import * as React from 'react';
import Helmet from 'react-helmet';
import Layout from '../layouts/Layout';

interface Props {
  title: string;
  data: { markdownRemark: { html: string } };
}

const LegalDocument: React.StatelessComponent<Props> = ({ title, data }) => (
  <Layout>
    <Helmet>
      <title>{title}</title>
      <body className='small-header-page' />
    </Helmet>
    <div className='container'>
      <div className='legal-document'>
        <div className='content' dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </div>
    </div>
  </Layout>
);

export default LegalDocument;
